<template>
  <v-container class="about" fluid>
    <h4>{{ getVisitedPois().length }} von {{ getPois().length }} besucht.</h4>
    <v-progress-linear
      v-if="getPoisLoaded()"
      :value="getPercentage()"
      height="25"
    >
      <strong>{{ Math.ceil(getPercentage()) }}%</strong>
    </v-progress-linear>

    <v-divider></v-divider>

    <v-card v-if="getPoisLoaded()">
      <v-card-title>Besuchte Orte</v-card-title>
      <v-card-text>
        <poi-table :pois="getVisitedPoisData()" />
      </v-card-text>
    </v-card>

    <v-divider></v-divider>

    <v-card v-if="getPoisLoaded()">
      <v-card-title>Nicht besuchte Orte</v-card-title>
      <v-card-text>
        <poi-table :pois="getNotVisitedPoisData()" />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import PoiTable from "../components/poi/PoiTable.vue";
import { GET_POIS, GET_POI, GET_POIS_LOADED } from "../store/modules/poistore";
import { GET_VISITED_POIS } from "../store/modules/userstore";
export default {
  name: "ProgressView",
  components: { PoiTable },
  methods: {
    ...mapGetters({
      getPois: "pois/" + GET_POIS,
      getPoi: "pois/" + GET_POI,
      getPoisLoaded: "pois/" + GET_POIS_LOADED,
      getVisitedPois: "user/" + GET_VISITED_POIS,
    }),
    getPercentage() {
      const totalPoiCount = this.getPois().length;
      const visitedCount = this.getVisitedPois().length;

      //TODO will need more checks in case of supporting more cities at once
      return Math.ceil((visitedCount / totalPoiCount) * 100);
    },
    getVisitedPoisData() {
      const data = this.getVisitedPois()
        .map((id) => {
          const poi = this.getPoi()(id);
          return poi;
        })
        .filter((p) => p !== undefined);
      return data;
    },
    getNotVisitedPoisData() {
      const t = this.getPois().filter(
        (f) => this.getVisitedPois().findIndex((id) => f.id == id) == -1
      );

      return t;
    },
  },
};
</script>
<style scoped>
/* .card-actions {
  flex-wrap: wrap;
} */
</style>
